<template>
  <router-view />
</template>

<script>
import { $themeConfig } from '@themeConfig'

export default {
  computed: {
    isVertical() {
      return $themeConfig.layout.type === 'vertical'
    },
  },
}
</script>
